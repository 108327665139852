import "./Footer.scss";

const Footer = (props) => {
  let classes = "footer";
  if(props.extraClass){
    classes = classes + " " + props.extraClass;
  }
  return (
    <footer className={classes}>
      <h4 className="footer__text">
       Concept by Danique Valstar & Florine Komen
      </h4>
      <h4 className="footer__text">
        Developed by Jeroen Rijsdijk
      </h4>
    </footer>
  );
};

export default Footer;
