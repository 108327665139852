const interactives = [
  {
    number: 1,
    interactiveTitle: "LUMOS",
    progress: 19,
    title: "Runes:  Puzzle 2",
    previous: "/dialogue/1",
    next: "/dialogue/4",
    background: "hallway gameCard--lumos-overflow",
    img: "PUZZLE2-1",
    text: "What do the runes say?",
    answer: "157",
    errorMessage: ["TERRY","“Nothing seems to be happening, are you sure this is the right translation?”"],
    hints:[
      {
        title: "I don't understand this puzzle.",
        text:"I can see you’re in the dark about this puzzle. Why don’t you take a closer look at the clusters of runes. I’m sure you can translate them using Finnian’s notes."
      },
      {
        title:"I have no clue what I need to do.",
        text: "Have you considered decoding those rune clusters in the right order? You know, left to right. It might just do the trick."
      },
      {
        title: "I just want the answer!",
        text:"Once you have translated all the clusters of runes you will get the following letters: HID-DEN SEC-RET. So the answer is quite obviously: ‘hidden secret’."
      }],
      items: {parchment:1, potion:false}
  },
  {
    number: 2,
    interactiveTitle: "CAULDRON",
    progress: 45,
    title: "Potions class: Puzzle 2",
    previous: "/dialogue/1",
    next: "/puzzle/6",
    background: "potions gameCard--potions--darker",
    img: "PUZZLE2-1",
    text: "In what order should you add your ingredients to the cauldron? (Click on the ingredients in the right order)",
    answer: "157",
    errorMessage: ["PROFESSOR ROOKS","“Who needs a recipe? Just kidding, you do. But don’t let this setback bring you down and try to find the eight right ingredients.”"],
    hints:[
      {
        title: "I don't understand this puzzle.",
        text:"Have you tried thinking outside the cauldron? You need to select the right order for your ingredients. Pay special attention to the letter that every ingredient starts with, can you find it somewhere else?"
      },
      {
        title:"I have no clue what I need to do.",
        text: "You need to take a look at the word on the cauldron, which coincidentally is the word cauldron, ironic huh? Tap the ingredients in the same order as the word cauldron. So for example the first letter is a ‘c’ so tap the corresponding image of the ‘crushed beetle’."
      },
      {
        title: "I just want the answer!",
        text:"The answer you’re looking for is the following order: crushed beetle, acorn, unicorn horn, leech, dandelion, rat tail, onion, nettle. Tap them in this order and you will see that your potion is brewing correctly!"
      }],
      items: {parchment:2, potion:false}

    
  },
  {
    number: 3,
    interactiveTitle: "DUEL",
    progress: 87,
    title: "The Duel",
    next: "/interactive/4",
    previous: "/dialogue/1",
    background: "forest",
    answer: "NOANSWERPLACEHOLDER123",

    text: "Oh no! Galen’s little sister Serena is turning towards you, you should stop her by knocking her back!",
    spells: [
      {
        title: "FLIPENDO",
        answer: true,
      },
      {
        title: "TITILLANDO",
        answer: false,
      },
      {
        title: "PETRIFICUSTOTALUS",
        answer: false,
      },
    ],
    hints:[
      {
        title: "I don't understand this puzzle.",
        text: "To solve this puzzle you need to click on the right spell in order to cast it in your duel. When you select the right spell you move onto the next opponent, if you select the wrong spell you lose and have to start over.",
      },
      {
        title:"I have no clue what I need to do.",
        text:"With every opponent you get a limited amount of time to cast the right spell. In the description of the attack you will find a clue as to which spell you need to use on that specific opponent. ", 
      },
      {
        title: "What do the spells do?",
        text: ["Flipendo > a jinx that can be used to physically repel an opponent.", "Petrificus Totalus > a spell that freezes or petrifies the body of the victim, making it incapable of moving.", "Tittilando > this is a tickling curse."],
      },
      {
        title:"How do we get away unseen?",
        text: "To get away from the battle you need to be able to get away unseen. If only there was a way for you to become invisible..."
      },
      {
        title:"I just want the answer!",
        text: "The right order is: Flipendo, Impedimenta, Petrificus totalus, Locomotor Wibbly, Levicorpus, Invisibility potion (click on the potion, button below the hints and parchment icon -> picture of potion)."
      }
    ],
    items: {parchment:4, potion:true}

  },
  {
    number: 4,
    interactiveTitle: "DUEL",
    progress: 87,
    title: "The Duel",
    previous: "/dialogue/1",
    background: "forest",
    text: "One of their friends is running towards you at full speed, try to slow him down!",
    next: "/interactive/5",
    answer: "NOANSWERPLACEHOLDER123",

    spells: [
      {
        title: "REDUCTO",
        answer: false,
      },
      {
        title: "IMPEDIMENTA",
        answer: true,
      },
      {
        title: "COLLOSHOO",
        answer: false,
      },
    ],
    hints:[
      {
        title: "I don't understand this puzzle.",
        text: "To solve this puzzle you need to click on the right spell in order to cast it in your duel. When you select the right spell you move onto the next opponent, if you select the wrong spell you lose and have to start over.",
      },
      {
        title:"I have no clue what I need to do.",
        text:"With every opponent you get a limited amount of time to cast the right spell. In the description of the attack you will find a clue as to which spell you need to use on that specific opponent. ", 
      },
      {
        title: "What do the spells do?",
        text: ["Reducto > a curse that blasts solid objects to pieces.", "Impedimenta > a jinx that causes the victim to trip or get blasted backwards.", "Colloshoo > sticks the target's shoes to the ground, causing them to trip if they attempt to move"],
      },
      {
        title:"How do we get away unseen?",
        text: "To get away from the battle you need to be able to get away unseen. If only there was a way for you to become invisible..."
      },
      {
        title:"I just want the answer!",
        text: "The right order is: Flipendo, Impedimenta, Petrificus totalus, Locomotor Wibbly, Levicorpus, Invisibility potion (click on the potion, button below the hints and parchment icon -> picture of potion)."
      }
    ],
    items: {parchment:4, potion:true}

  },
  {
    number: 5,
    interactiveTitle: "DUEL",
    progress: 87,
    title: "The Duel",
    previous: "/dialogue/1",
    next: "/interactive/6",
    background: "forest",
    answer: "NOANSWERPLACEHOLDER123",
    text: "Before you can look around to see if your friends need help, another opponent is in your way. Make sure they can’t move any closer by freezing their motions.",
    spells: [
      {
        title: "PETRIFICUSTOTALUS",
        answer: true,
      },
      {
        title: "STUPEFY",
        answer: false,
      },
      {
        title: "TARANTALLEGRA",
        answer: false,
      },
    ],
    hints:[
      {
        title: "I don't understand this puzzle.",
        text: "To solve this puzzle you need to click on the right spell in order to cast it in your duel. When you select the right spell you move onto the next opponent, if you select the wrong spell you lose and have to start over.",
      },
      {
        title:"I have no clue what I need to do.",
        text:"With every opponent you get a limited amount of time to cast the right spell. In the description of the attack you will find a clue as to which spell you need to use on that specific opponent. ", 
      },
      {
        title: "What do the spells do?",
        text: ["Petrificus Totalus > a spell that freezes or petrifies the body of the victim, making it incapable of moving.", "Stupefy > the Stunning Spell which render its target unconscious.", "Titillando > a hex that tickles and subsequently weakens the target. The Dancing Feet Spell is a charm used to force another person's legs to begin dancing uncontrollably."],
      },
      {
        title:"How do we get away unseen?",
        text: "To get away from the battle you need to be able to get away unseen. If only there was a way for you to become invisible..."
      },
      {
        title:"I just want the answer!",
        text: "The right order is: Flipendo, Impedimenta, Petrificus totalus, Locomotor Wibbly, Levicorpus, Invisibility potion (click on the potion, button below the hints and parchment icon -> picture of potion)."
      }
    ],
    items: {parchment:4, potion:true}

  },
  {
    number: 6,
    interactiveTitle: "DUEL",
    progress: 87,
    title: "The Duel",
    previous: "/dialogue/1",
    next: "/interactive/7",
    background: "forest",
    answer: "NOANSWERPLACEHOLDER123",
    text: "Galen turns his attention to you. Quick! Make sure he can’t run over by making his legs turn to jelly.",
    spells: [
      {
        title: "TITILLANDO",
        answer: false,
      },
      {
        title: "LEVICORPUS",
        answer: false,
      },
      {
        title: "LOCOMOTORWIBBLY",
        answer: true,
      },
    ],
    hints:[
      {
        title: "I don't understand this puzzle.",
        text: "To solve this puzzle you need to click on the right spell in order to cast it in your duel. When you select the right spell you move onto the next opponent, if you select the wrong spell you lose and have to start over.",
      },
      {
        title:"I have no clue what I need to do.",
        text:"With every opponent you get a limited amount of time to cast the right spell. In the description of the attack you will find a clue as to which spell you need to use on that specific opponent. ", 
      },
      {
        title: "What do the spells do?",
        text: ["Tittilando > this is a tickling curse.", "Levicorpus > a spell which dangles the target person upside-down by the ankle in mid-air.", "Locomotor Wibbly > a dark charm that causes the victim's legs to collapse as if turned to jelly."],
      },
      {
        title:"How do we get away unseen?",
        text: "To get away from the battle you need to be able to get away unseen. If only there was a way for you to become invisible..."
      },
      {
        title:"I just want the answer!",
        text: "The right order is: Flipendo, Impedimenta, Petrificus totalus, Locomotor Wibbly, Levicorpus, Invisibility potion (click on the potion, button below the hints and parchment icon -> picture of potion)."
      }
    ],
    items: {parchment:4, potion:true}

  },
  {
    number: 7,
    interactiveTitle: "DUEL",
    progress: 87,
    title: "The Duel",
    previous: "/dialogue/1",
    background: "forest",
    next: "/interactive/8",
    answer: "NOANSWERPLACEHOLDER123",
    text: "Silas is now the only one left standing. You don’t want him to follow you, so leave him dangling in the air.",
    spells: [
      {
        title: "COLLOSHOO",
        answer: false,
      },
      {
        title: "LEVICORPUS",
        answer: true,
      },
      {
        title: "IMPEDIMENTA",
        answer: false,
      },
    ],
    hints:[
      {
        title: "I don't understand this puzzle.",
        text: "To solve this puzzle you need to click on the right spell in order to cast it in your duel. When you select the right spell you move onto the next opponent, if you select the wrong spell you lose and have to start over.",
      },
      {
        title:"I have no clue what I need to do.",
        text:"With every opponent you get a limited amount of time to cast the right spell. In the description of the attack you will find a clue as to which spell you need to use on that specific opponent. ", 
      },
      {
        title: "What do the spells do?",
        text: ["Colloshoo > sticks the target's shoes to the ground, causing them to trip if they attempt to move.", "Levicorpus > a spell which dangles the target person upside-down by the ankle in mid-air.", "Impedimenta > a jinx that causes the victim to trip or get blasted backwards."],
      },
      {
        title:"How do we get away unseen?",
        text: "To get away from the battle you need to be able to get away unseen. If only there was a way for you to become invisible..."
      },
      {
        title:"I just want the answer!",
        text: "The right order is: Flipendo, Impedimenta, Petrificus totalus, Locomotor Wibbly, Levicorpus, Invisibility potion (click on the potion, button below the hints and parchment icon -> picture of potion)."
      }
    ],
    items: {parchment:4, potion:true}

  },
  {
    /* goes to dialogue 20, not to next.*/
    number: 8,
    interactiveTitle: "DUEL",
    progress: 87,
    title: "The Duel",
    previous: "/dialogue/1",
    next: "/interactive/7",
    background: "forest",
    answer: "NOANSWERPLACEHOLDER123",
    canIUsePotion: true,
    text: "Time is running out! Before they regain their strength, you need something to get away unseen!",
    spells: [
      {
        title: "REDUCTO",
        answer: false,
      },
      {
        title: "PETRIFICUSTOTALUS",
        answer: false,
      },
      {
        title: "STUPEFY",
        answer: false,
      },
    ],
    hints:[
      {
        title: "I don't understand this puzzle.",
        text: "To solve this puzzle you need to click on the right spell in order to cast it in your duel. When you select the right spell you move onto the next opponent, if you select the wrong spell you lose and have to start over.",
      },
      {
        title:"I have no clue what I need to do.",
        text:"With every opponent you get a limited amount of time to cast the right spell. In the description of the attack you will find a clue as to which spell you need to use on that specific opponent. ", 
      },
      {
        title: "What do the spells do?",
        text: ["Reducto > a curse that blasts solid objects to pieces.", "Petrificus Totalus > a spell that freezes or petrifies the body of the victim, making it incapable of moving.", "Stupefy > the Stunning Spell which render its target unconscious. Titillando > a hex that tickles and subsequently weakens the target"],
      },
      {
        title:"How do we get away unseen?",
        text: "To get away from the battle you need to be able to get away unseen. If only there was a way for you to become invisible..."
      },
      {
        title:"I just want the answer!",
        text: "The right order is: Flipendo, Impedimenta, Petrificus totalus, Locomotor Wibbly, Levicorpus, Invisibility potion (click on the potion, button below the hints and parchment icon -> picture of potion)."
      }
    ],
    items: {parchment:4, potion:true}

  },
];

export default interactives;
