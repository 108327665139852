const items = [
  {
    item: 1,
    next: "/dialogue/2",
    previous: "",
    title: "Chapter 2: Terry's Vision",
    text: [
      "What could have happened to the unfortunate wizard in Terry's vision,",
      "and what does the strange symbol etched into the tree mean?",
    ],
    progress: 13,
    image: "PARCHMENT--1",
    type: {
      name: "PARCHMENT",
      number: 1,
    },
    items: {parchment:1, potion:false}
  },
  {
    item: 2,
    next: "/chapter/4",
    previous: "",
    title: "New parchment piece obtained!",
    text: [
      "Centaurs were involved in Terry’s vision?",
      "Would they have killed the wizard just like that?",
    ],
    progress: 38,
    image: "PARCHMENT--2",
    type: {
      name: "PARCHMENT",
      number: 2,
    },
    items: {parchment:2, potion:false}

  },
  {
    item: 3,
    next: "/chapter/5/part/1",
    previous: "",
    title: "Invisibility Potion obtained!",
    text: [
      "Congratulations! Professor Rooks chose you and Annya’s potion as the top of the class.",
      " He has gifted you the Invisibility potion, use it well...",
    ],
    progress: 48,
    image: "POTION",
    type: {
      name: "POTION",
    },
    items: {parchment:2, potion:true}

  },
  {
    item: 4,
    next: "/dialogue/14",
    previous: "",
    title: "New parchment piece obtained!",
    text: ["It appears that Nathaniel wasn't actually killed by a centaur after all..."],
    progress: 62,
    image: "PARCHMENT--3",
    type: {
      name: "PARCHMENT",
      number: 3,
    },
    items: {parchment:3, potion:true}

  },
  {
    item: 5,
    next: "/dialogue/21",
    previous: "",
    title: "Expergi seeds obtained!",
    text: ["Wow, they look so magical!" ,"Could this really be the cure to help Leonora’s family?"],
    progress: 92,
    image: "EXPERGI",
    items: {parchment:4, potion:false}
  },
  {
    item: 6,
    next: "/dialogue/19",
    previous: "",
    title: "New parchment piece obtained!",
    text: ["It looks like you need to find this magical flower that hides deep in The Forbidden Forest."],
    progress: 80,
    image: "PARCHMENT--4",
    type: {
      name: "PARCHMENT",
      number: 4,
    },
    items: {parchment:4, potion:false}
  },
];

export default items;
