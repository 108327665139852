import { Link } from "react-router-dom";
import iconHelper from "../../helpers/iconHelper";
import { scrollToTop } from "../../helpers/scrollHelpers";
import "./Navigation.scss";
const Navigation = ({setOpenNavigation}) => {
  return (
    <>
      <nav className="navigation">
        <button onClick={() => setOpenNavigation(true)} className="navigation__hamburger">
          <img src={iconHelper("HAMBURGER").img} alt={iconHelper("HAMBURGER").alt} />
        </button>
        <ul className="navigation__list navigation__list--first">
          <li className="navigation__listItem">
          <Link to="/" onClick={() => scrollToTop()} className="navigation__link">Home</Link>
          </li>
          <li className="navigation__listItem">
            <Link to="/about" onClick={() => scrollToTop()}  className="navigation__link">About</Link>
          </li>
          <li className="navigation__listItem">
            <a className="navigation__link" href="#id">
              Credits
            </a>
          </li>
        </ul>
        <ul className="navigation__list navigation__list--second">
          <li>
            <a className="navigation__link" href="#id">
              <img
                src={iconHelper("INSTAGRAM").img}
                alt={iconHelper("INSTAGRAM").alt}
                className="navigation__social"
              />
            </a>
          </li>
          <li>
            <a className="navigation__link" href="#id">
              <img
                src={iconHelper("FACEBOOK").img}
                alt={iconHelper("FACEBOOK").alt}
                className="navigation__social"
              />
            </a>
          </li>
          <li>
            <a className="navigation__link" href="#id">
              <img
                src={iconHelper("MAIL").img}
                alt={iconHelper("MAIL").alt}
                className="navigation__social"
              />
            </a>
          </li>
        </ul>
      </nav>
    </>
  );
};

export default Navigation;
