import "./GlassCard.scss"

const GlassCard = () =>{
    return(
        <article className="glassCard">
            <header className="glassCard__header">
                <h1 className="glassCard__heading">Before you start...</h1>
            </header>
            <section className="glassCard__body">
                <ul className="glassCard__points">
                    <li className="glassCard__point">Play on desktop or laptop</li>
                    <li className="glassCard__point">By starting the game, you agree to our use of Google Analytics to track game statistics</li>
                    <li className="glassCard__point">Have a quill and parchment ready to take notes</li>
                    <li className="glassCard__point">Click the sorting hat icon when stuck</li>
                    <li className="glassCard__point">Save the URL to resume later</li>
                    <li className="glassCard__point">Play online with friends using Zoom or Skype</li>
                    <li className="glassCard__point">Use a timer to track your complete time</li>
                </ul>
            </section>
        </article>
    )
}

export default GlassCard;