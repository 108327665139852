const dialogue = [
  {
    number: 1,
    progress: 4,
    title: "Herbology class",
    previous: "/chapter/1",
    background: "herbology",
    dialogues: [
      {
        character: "Finnian",
        text: "I can't believe our first week back at Hogwarts is almost over, time is going by so fast!",
      },
      {
        character: "Annya",
        text: "*sighs* I wish this lesson would go by fast.",
      },
      {
        character: "Leonora",
        text: "You still don't like Herbology then, Annya?",
      },
      {
        character: "Annya",
        text: "Unfortunately after what happened last year I don't think I will ever look at plants the same again. I mean how was to that even possible, that thing just grew teeth outof nowhere and-",
      },
      {
        character: "Professor Marigold",
        text: "Attention class, today we will be starting off our lesson with the Xanthoceras Sorbifolium, a magical plant known for its healing abilities. Now, who can tell me how many days of sun a year the Xantheroceras needs?",
        next: "/puzzle/1",
      },
    ],
    items: {parchment:0, potion:false}
  },
  {
    number: 2,
    progress: 15,
    title: "Terry's Vision",
    previous: "/chapter/1",
    background: "hallway",
    dialogues: [
      {
        character: "Annya",
        text: "This looks just like any ordinary hallway, are you sure it's the one?",
      },
      {
        character: "Terry",
        text: "Yes, just look around for the symbol I told you guys about.",
      },
      {
        character: "Finnian",
        text: "Three oddly sized circles, got it.",
        next: "/dialogue/3",
      },
    ],
    items: {parchment:1, potion:false}
  },
  {
    number: 3,
    progress: 15,
    title: "Herbology class",
    previous: "/chapter/1",
    background: "hallway--dark",
    dialogues: [
      {
        character: "Annya",
        text: "Well this is going great...",
      },
      {
        character: "Terry",
        text: "Always the optimist.",
      },
      {
        character: "Leonora",
        text: "Guys this isn't funny. What if someone is messing with us.",
      },
      {
        character: "Finnian",
        text: "Don't worry, Leonora. I won't let anyone hurt you again.",
        next: "/merge/1",
      },
    ],
    items: {parchment:1, potion:false}
  },
  {
    number: 4,
    progress: 20,
    title: "Chapter 2: Terry's Vision",
    previous: "/chapter/1",
    background: "hallway",
    dialogues: [
      {
        character: "Annya",
        text: "Look! That wall over there is moving.",
      },
      {
        character: "Terry",
        text: "Hmm how cliche, a secret passage.",
      },
      {
        character: "Annya",
        text: "You sound disappointed.",
      },
      {
        character: "Finnian",
        text: "Ahem, we should probably take a closer look.",
        next: "/dialogue/5",
      },
    ],
    items: {parchment:1, potion:false}

  },
  {
    number: 5,
    progress: 23,
    title: "Runes: Puzzle 2",
    previous: "/chapter/1",
    background: "closeUpWall",
    dialogues: [
      {
        character: "Terry",
        text: "There! The symbol from my visions!",
      },
      {
        character: "Leonora",
        text: "Any idea what it could mean?",
      },
      {
        character: "Terry",
        text: "No clue, but it’s surrounded by more runes.",
      },
      {
        character: "Leonora",
        text: "Come on [Y/N], let’s take a closer look.",
        next: "/puzzle/2",
      },
    ],
    items: {parchment:1, potion:false}

  },
  {
    number: 6,
    progress: 25,
    title: "Runes: Puzzle 2",
    previous: "/chapter/1",
    background: "chest",
    dialogues: [
      {
        character: "Leonora",
        text: "Wow, it’s like a treasure chest! Was this in your visions too, Terry?",
      },
      {
        character: "Terry",
        text: "No, I haven’t seen it before. It doesn’t seem to budge though. It's locked tight.",
      },
      {
        character: "Annya",
        text: "Maybe there’s a hidden key somewhere around here too.",
      },
      {
        character: "Finnian",
        text: "Wait, look! The same strange symbol is etched onto the side of the chest.",
      },
      {
        character: "Annya",
        text: "Too bad that we still don’t have a clue what it means...",
        next: "/chapter/3",
      },
    ],
    items: {parchment:1, potion:false}

  },
  {
    number: 7,
    progress: 28,
    title: "A Christmas Miracle",
    previous: "/chapter/1",
    background: "greatHall",
    dialogues: [
      {
        character: "Annya",
        text: "Guys! You’re not gonna believe it!",
      },
      {
        character: "Leonora",
        text: "Did you find a way to open the chest?",
      },
      {
        character: "Annya",
        text: "Yes! Well, sort of I guess… Terry had a vision a few nights ago and I think it could lead us to unlocking the chest. He said he kept seeing the name of a specific book, hidden away somewhere on one of the library shelves. We should go there immediately after class.",
      },
      {
        character: "Finnian",
        text: "But Annya, it could take ages to find that book. In case you’ve forgotten, the library is huge!",
      },
      {
        character: "Annya",
        text: "I know, but we have to try. You haven’t seen my brother during Christmas break… He’s not been himself lately, I’m worried these visions are actually making him sick. I’ve barely seen him eat or sleep much at all. And as much as he might be a pain in the behind, he’s still my brother.",
        next: "/dialogue/8",
      },
    ],
    items: {parchment:1, potion:false}

  },
  {
    number: 8,
    progress: 30,
    title: "A Christmas Miracle",
    previous: "/chapter/1",
    background: "library",
    dialogues: [
      {
        character: "Terry",
        text: "We’re looking for a book about magical creatures by some bloke called Roman Barrett. It should be somewhere in this section of the library, so we’d better start looking.",
      },
      {
        character: "Annya",
        text: "Let’s go find that book! By the way, did you guys hear about what happened to professor Poults over Christmas?",
      },
      {
        character: "Annya",
        text: "It’s awful. They found the professor and her husband in their home during Christmas break. Apparently, they both ingested a very powerful sleeping potion and no antidote has yet been able to wake them up.",
      },
      {
        character: "Leonora",
        text: "I just can’t imagine why someone would want to hurt them, they did nothing wrong.",
      },
      {
        character: "Finnian",
        text: "I’m sure they will find a way to wake them up. Right [Y/N]?",
      },
      {
        character: "Annya",
        text: "Ugh, we are never going to find this book, we’ve been searching for hours!",
      },
      {
        character: "Leonora",
        text: "Have you guys noticed Silas and Galen keep glancing our way? I don’t like it. They've been steering clear of me in the common room ever since last year, and honestly, I think I prefer it.",
      },
      {
        character: "Annya",
        text: "Really? I haven't noticed, but I don't blame you for being paranoid. After the way they bullied you last year, I still can't believe the headmaster let them off with just a few months of detention.",
      },
      {
        character: "Leonora",
        text: "Yeah, it's unbelievable! They got off so easily. Still I can’t help but feel that they’re up to something...",
      },
      {
        character: "Finnian",
        text: "Hang on a second, I think I found it! His name was Barrett right? I’ve got a book right here, written by L.R. Barrett.",
      },
      {
        character: "Leonora",
        text: "Finnian you’re amazing! I mean- that’s amazing, does that mean we can open the chest?",
        next: "/merge/2",
      },
    ],
    items: {parchment:1, potion:false}

  },
  {
    number: 9,
    progress: 34,
    title: "Coins: Puzzle 2",
    previous: "/chapter/1",
    background: "library--chest--open",
    dialogues: [
      {
        character: "Annya",
        text: "I can’t believe this was the key to opening that magic chest, why didn’t we try etching words into the side before? Though I still don’t understand what seeds have to do with anything.",
      },
      {
        character: "Finnian",
        text: "These coins inside the chest look strange, they don’t look anything like regular Galleons.",
      },
      {
        character: "Leonora",
        text: "I wonder why they have magical creatures on them. Maybe one of the coins will tell us more?",
        next: "/puzzle/3",
      },
    ],
    items: {parchment:1, potion:false}

  },
  {
    number: 10,
    progress: 36,
    title: "Coins: Puzzle 2",
    previous: "/chapter/1",
    background: "library--chest--open",
    dialogues: [
      {
        character: "Leonora",
        text: "So we’re left with the centaur coin, I wonder what that means?",
      },
      {
        character: "Finnian",
        text: "Terry, are you okay? You seem distracted?",
      },
      {
        character: "Terry",
        text: "When I touched the centaur coin- I had another vision. I saw the same two men walking through the forest, they were trying to find something. But this time the vision didn’t stop there, I saw how they were stopped by a group of centaurs, one was holding a bow.",
      },
      {
        character: "Annya",
        text: "Did it tell you what they were looking for?",
      },
      {
        character: "Terry",
        text: "No… All I know is that the centaurs didn’t look pleased to see them there.",
        next: "/item-obtained/2",
      },
    ],
    items: {parchment:1, potion:false}

  },
  {
    number: 11,
    progress: 42,
    title: "Potions Class",
    previous: "/chapter/1",
    background: "potions",
    dialogues: [
      {
        character: "Professor Rooks",
        text: "Good afternoon students. Today we will be attempting to make the hair-raising potion. The pairing with the most successful potion will receive a reward at the end of the class.",
      },
      {
        character: "Annya",
        text: "Professor, what can you tell us about sleeping potions?",
      },
      {
        character: "Professor Rooks",
        text: "Ah, while I praise your thirst for knowledge Miss. Choi, I’m sure this has nothing to do with your education. I’m assuming by now you have all heard what happened to professor Poults and Mr. Travers.",
      },
      {
        character: "Professor Rooks",
        text: "As their teacher and colleague, it would be highly inappropriate for me to discuss the situation. But then again, who said anything about me being a good teacher?",
      },
      {
        character: "Professor Rooks",
        text: "Sleeping potions can be quite useful in the right circumstances. Say, if you need a good night's rest before a big exam or a stressful day. However, not every sleeping potion is equally innocent.",
      },
      {
        character: "Professor Rooks",
        text: "Take for example The Draught of Living Death, as the name suggests, it can put you in a deep sleep that resembles death - so deep, in fact, that even the most experienced wizards can mistake it for the real thing.",
      },
      {
        character: "Professor Rooks",
        text: "Even a single drop of this potion is enough to put a person in a state of sleep for weeks, months, or even years. And there's no known cure once you've taken it. It's clearly not something to be trifled with.",
      },
      {
        character: "Annya",
        text: "But the person always wakes up right?",
      },
      {
        character: "Professor Rooks",
        text: "Sometimes. Like I said, the effect of one drop could take years, it can never be said how much of the potion someone has ingested… Ahem, that should answer your question. Now let’s start brewing a potion we can cure with a simple comb.",
        next: "/puzzle/4",
      },
    ],
    items: {parchment:2, potion:false}

  },
  {
    number: 12,
    progress: 52,
    title: "Chapter 5: the painting of Prof. Fawley",
    previous: "/chapter/1",
    background: "staircase",
    dialogues: [
      {
        character: "Leonora",
        text: "Professor Fawley, we need your help!",
      },
      {
        character: "Professor Fawley",
        text: "Typical young students, always engrossed in their own world and hardly taking the time to speak with me, except when they need my help.",
      },
      {
        character: "Annya",
        text: "Professor, this is serious, we really need this information, people’s lives are at stake! We think we have found a way to-",
      },
      {
        character: "Terry",
        text: "What my little sister is trying to say is that they have a very important paper to write for their care of magical creatures class. You could say their lives depend on getting a good grade. And we heard you are quite the expert on the subject of centaurs.",
      },
      {
        character: "Professor Fawley",
        text: "I guess you can’t talk to the woman who currently teaches magical creatures, professor Poults. But then again, I suppose I am far more knowledgeable. Very well, I will answer some of your questions but only if you answer some of mine first.",
        next: "/puzzle/7",
      },
    ],
    items: {parchment:2, potion:true}

  },
  {
    number: 13,
    progress: 58,
    title: "Fawley's Painting",
    previous: "/chapter/1",
    background: "staircase",
    dialogues: [
      {
        character: "Professor Fawley",
        text: "laughs I was just having a laugh with you lot. I can’t believe you actually went through all that effort just to have a word with me. Very well, I guess I could amuse myself and answer a question or two...",
        next: "/chapter/5/part/2",
      },
    ],
    items: {parchment:2, potion:true}
  },
  {
    number: 14,
    progress: 64,
    title: "Divination Classroom",
    previous: "/chapter/1",
    background: "hallway--divination",
    dialogues: [
      {
        character: "Finnian",
        text: "So could someone please remind me why we are sneaking into the Divination classroom at night?",
      },
      {
        character: "Terry",
        text: "Because we don’t want to get caught.",
      },
      {
        character: "Leonora",
        text: "Do you really think this will work? I mean, do we even know if your visions will be stronger when using a crystal ball?",
      },
      {
        character: "Annya",
        text: "Guys, come on, this is probably our only shot at finding out what happened to Nathaniel in the forbidden forest.",
      },
      {
        character: "Terry",
        text: "Well we already know what happened, he bit the dust. What I want to know is why.",
      },
      {
        character: "Annya",
        text: "Why do I keep sticking up for you...",
      },
      {
        character: "Terry",
        text: "Because I’m a role model. Now, let’s break into this classroom.",
        next: "/dialogue/15",
      },
    ],
    items: {parchment:3, potion:true}
  },
  {
    number: 15,
    progress: 66,
    title: "Divination Classroom",
    previous: "/chapter/1",
    background: "divination",
    dialogues: [
     {
        character: "Annya",
        text:"I can’t believe you nicked the key.",
     },
     {
        character: "Terry",
        text:"I can’t believe you tried Alohomora.",
     },
     {
        character: "Leonora",
        text:"Oh, my goodness, this classroom is a bit unusual. I wonder where the professor keeps the crystal balls.",
     },
     {
        character: "Terry",
        text:"They’re locked in a cabinet over there, the only thing is, I didn’t find a key for it. Oh I know, maybe we should try opening it with Alohomora.",
     },
     {
        character: "Annya",
        text:"Very funny.",
     },
     {
        character: "Finnian",
        text:"Look, over here! I think I found the way to open the cabinet. The only thing is, I don’t understand any of it. What do you think [Y/N]?",
        next: "/puzzle/8",
      }
    ],
    items: {parchment:3, potion:true}

  },
  {
    number: 16,
    progress: 70,
    title: "Divination Classroom",
    previous: "/chapter/1",
    background: "divination",
    dialogues: [
     {
        character: "Annya",
        text:"Well done! What a weird way to lock a cabinet though… Well Terry, I guess now’s your chance to go all psychic on us. Here is your crystal ball, would you like a matching head scarf?",
     },
     {
        character: "Terry",
        text:"Just so you know, if my eyes roll back, it's not because I'm having a vision. It's because you're so annoying.",
     },
     {
        character: "Finnian",
        text:"Guys, we should really focus on the mission. The longer we stay out here, the longer we risk getting caught.",
     },
     {
        character: "Leonora",
        text:"Finnian is right, we have to hurry. Plus, this classroom is starting to give me the creeps.",
     },
     {
        character: "Terry",
        text:"Okay, can everyone keep quiet now? I need to concentrate.",
        next: "/puzzle/9",
      },
    ],
    items: {parchment:3, potion:true}

  },
  {
    number: 17,
    progress: 74,
    title: "Divination Classroom",
    previous: "/chapter/1",
    background: "divination",
    dialogues: [
     {
        character: "Leonora",
        text:"Oh my god, I think it’s showing us at what time we need to be in the forbidden forest.",
     },
     {
        character: "Terry",
        text:"We should… find out the- what the cure looks like.",
     },
     {
        character: "Annya",
        text:"Are you okay?",
     },
     {
        character: "Terry",
        text:"’m fine, we should keep going.",
        next: "/puzzle/10",
     },
    ],
    items: {parchment:3, potion:true}

  },
  {
    number: 18,
    progress: 77,
    title: "Divination Classroom",
    previous: "/chapter/1",
    background: "divination",
    dialogues: [
     {
        character: "Finnian",
        text:"What a strange name, could they be connected to the symbol?",
     },
     {
        character: "Annya",
        text:"Terry, have you ever seen this before in your vision? I’m sorry but Expergi seeds sound ridiculous to me.",
     },
     {
        character: "Terry",
        text:"I- faints",
     },
     {
        character: "Annya",
        text:"TERRY?!",
        next: "/chapter/6",
     },
    ],
    items: {parchment:3, potion:true}

  },
  {
    number: 19,
    progress: 83,
    title: "The Forbidden Forest",
    previous: "/chapter/1",
    background: "forest",
    dialogues: [
     {
        character: "Terry",
        text:"I can’t believe it was right in front of us the whole time. The symbol had nothing to do with ancient runes, they were seeds!",
     },
     {
        character: "Annya",
        text:"Are you sure you should be coming along with us? Maybe we should have stopped by the hospital wing first.",
     },
     {
        character: "Terry",
        text:"And tell them I fainted because I looked into a crystal ball after we broke into a classroom? I’ll be in St Mungo's faster than I can say divination. Besides, you have no clue what the flower looks like.",
     },
     {
        character: "Annya",
        text:"I’m pretty sure we’ll be able to spot a magical flower with glowing seeds in this dark, gloomy forest.",
     },
     {
        character: "Leonora",
        text:"Maybe we should figure out what we’re going to do when we actually find this magical flower. I mean, remember the warning in the vision - every possible creature will try to stop us from taking it.",
     },
     {
        character: "Finnian",
        text:"Leonora is right, we need to come up with a plan in case they attack us. A plan that doesn’t involve us trying to outrun a centaur.",
     },
     {
        character: "Terry",
        text:"I don’t know, mate. I mean I am pretty fast so-",
     },
     {
        character: "Annya",
        text:"Wait, shut up, does anyone else get the feeling we’re being followed?",
        next: "/chapter/7/part/1",
     },
    ],
    items: {parchment:4, potion:true}

  },
  {
    number: 20,
    progress: 88,
    title: "The Forbidden Forest",
    previous: "/chapter/1",
    background: "forest",
    dialogues: [
     {
        character: "Terry",
        text:"Is everyone okay?",
     },
     {
        character: "Annya",
        text:"Unbelievable! Those guys have no moral compass whatsoever.",
     },
     {
        character: "Finnian",
        text:"Leonora, are you alright? I’m sorry I didn’t notice them following us sooner. We should have taken your concerns more seriously.",
     },
     {
        character: "Leonora",
        text:"I’m alright, but I don't understand. What could they possibly want with the Expergi seeds? Unless their goal was to prevent us from gathering them...",
     },
     {
        character: "Terry",
        text:"Speaking of, we should probably get back to finding those flowers. Or it will all have been for nothing. Let’s go [Y/N]",
        next: "/chapter/7/part/2",
      },
     
    ],
    items: {parchment:4, potion:false}


  },
  {
    number: 21,
    progress: 94,
    title: "The Forbidden Forest",
    previous: "/chapter/1",
    background: "forest",
    dialogues: [
     {
        character: "Leonora",
        text:"I can't believe we actually did it...",
     },
     {
        character: "Finnian",
        text:"We’re going to save your family, Leonora. I told you we would find a way.",
     },
     {
        character: "Terry",
        text:"What we should find is a way back, we don’t want to celebrate too soon.",
     },
     {
        character: "Annya",
        text:"Now who’s the pessimist?",
        next: "/dialogue/22",
     },     
    ],
    items: {parchment:4, potion:false}

  },
  {
    number: 22,
    progress: 96,
    title: "The Forbidden Forest",
    previous: "/chapter/1",
    background: "hogwartsGrounds",
    dialogues: [
     {
        character: "Finnian",
        text:"We’re almost at the castle, we should decide what to do with the seeds.",
     },
     {
        character: "Leonora",
        text:"Look, over there by the Greenhouses, it's Professor Marigold! We should stop her!",
     },
     {
        character: "Professor Marigold",
        text:"What is the meaning of this? Goodness, when my wife told me that teaching teenagers would mean trouble I thought she meant skipping class, not sneaking off to the Forbidden Forest in the middle of the night. You could have gotten yourselves killed!.",
     },
     {
        character: "Leonora",
        text:"But Professor, we have the cure!",
     },   
     {
        character: "Professor Marigold",
        text:"I’m sorry dear, I’m not sure I understand. Cure for what?",
        next: "/chapter/8",
     },       
     
    ],
    items: {parchment:4, potion:false}

  },
  
];

export default dialogue;
